import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mounting-up",
      "style": {
        "position": "relative"
      }
    }}>{`Mounting Up`}<a parentName="h1" {...{
        "href": "#mounting-up",
        "aria-label": "mounting up permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <blockquote>
      <p parentName="blockquote">{`Market dynamics since this guild was created have changed, and shown that Olympus (and Klima), while good on the way up, is not particular resilient to downturns. However, we leave the content as it was in order to illustrate two important facts: (i) always be humble as none of us are right very often and (ii) learning often happens most effectively through our mistakes, which we need to acknowledge in order to truly integrate. `}</p>
    </blockquote>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This first gathering will focus on OlympusDAO and the token OHM. We'll also take a look at KlimaDAO, who have forked Olympus' strategy and aim to create ecosystem of projects building with programmable carbon.`}</p>
    <p>{`OlympusDAO began because we still do not have an independently valued digital currency and seeks to solve this by asking the question, "What if we could achieve stability while still maintaining a floating market-driven price?"`}</p>
    <p>{`In line with the introduction to this guild, OHM's careful design has allowed the community to make a particularly potent meme from a fairly dry and technical concept: (3,3) is no longer just an expression understood by those who know what the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Prisoner%27s_dilemma"
      }}>{`prisoner's dilemma`}</a>{`, but signifies in an easily-sharable way the notion of win-win incentive structures.`}</p>
    <p>{`In addition to this, the mechanism has ensured that OlympusDAO owns nearly all their own liquidity, which is significantly different to most other DeFi projects.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://every.to/almanack/olympus-dao"
        }}>{`Enter Olympus`}</a>{`, with `}<a parentName="li" {...{
          "href": "https://dirtroads.substack.com/p/-18-olympus-devourer-of-planets"
        }}>{`care`}</a>{`. Be sure to `}<a parentName="li" {...{
          "href": "https://twitter.com/cyounessi1/status/1483594676145954818"
        }}>{`read both sides`}</a>{`.`}</li>
      <li parentName="ol">{`Read `}<a parentName="li" {...{
          "href": "https://twitter.com/scupytrooples/status/1447398430574141445"
        }}>{`scupytrooples`}</a>{`.`}</li>
      <li parentName="ol">{`Watch this `}<a parentName="li" {...{
          "href": "https://youtu.be/o699i_l_qy8"
        }}>{`simple whiteboard explainer`}</a></li>
      <li parentName="ol">{`Listen to the `}<a parentName="li" {...{
          "href": "https://anchor.fm/missiondefi/episodes/EP-15---Is-Zeus-of-Olympus-DAO-the-god-of-protocol-planning-we-didnt-know-we-needed--OHM-e17vkc1"
        }}>{`voice of Zeus`}</a>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "group-work",
      "style": {
        "position": "relative"
      }
    }}>{`Group Work`}<a parentName="h2" {...{
        "href": "#group-work",
        "aria-label": "group work permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The recording for this session is not available, but you can follow the work we did together when in discussion `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/Q403umcOyBmLI68TL3mVxb/OHM?node-id=0%3A1"
      }}>{`on this Figma board`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      